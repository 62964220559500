import styles from './buttom-custom.module.scss'
import {Button, ButtonProps} from "@mui/material";


export const ButtonCustom = ({children, ...props}:ButtonProps) => {
  return (
    <Button
      className={styles.customBtn}
      variant="contained"
      fullWidth
      disableRipple
      sx={{
        borderRadius: '10px',
        backgroundColor: '#4794EF',
        fontSize: '18px',
        lineHeight: '20px',
        fontWeight: 600,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '15px 20px',
        color: '#EEF2F4',
        textTransform: 'none',
        boxShadow: 'none',
        '&:hover' : {
        backgroundColor: '#2D74E3',
        boxShadow: 'none',
      },
        '&:active, &:focus': {
        backgroundColor: '#2460D1',
        boxShadow: 'none',
      },
        '&:disabled': {
        backgroundColor: '#C1E0FC',
        boxShadow: 'none',
      }
      }}
      {...props}
    >
      {children}
    </Button>
  );
};
