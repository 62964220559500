import React from 'react';
import styles from './restore-pass.module.scss';
import {Box} from "@mui/material";
import {MailInput} from "shared/ui/mail-input";
import {ButtonCustom} from "shared/ui/button-custom";
import {useNavigate} from "react-router-dom";

export const RestorePass = () => {
  let navigate = useNavigate();
  const goOtp = () => {
    navigate('/auth/restore_pass/otp');
  }


  return (
    <Box className={styles.loginPage}>
      <Box className={styles.loginTitleBlock}>
        <h2 className={styles.loginTitle}>
          <span className={styles.loginTitleBlack}>Восстановление пароля</span>
        </h2>
        <span className={styles.loginDesc}>Укажите адрес электронной почты для восстановления пароля.</span>
      </Box>
      <Box className={styles.loginForm}>
        <Box className={styles.loginInputBlock}>
          <Box className={styles.loginInputBack}>
            <MailInput />
          </Box>
        </Box>
        <Box className={styles.submitBtn}>
          <ButtonCustom onClick={goOtp}>
            Продолжить
          </ButtonCustom>
        </Box>
      </Box>
    </Box>
  );
};
