import {Container} from "@mui/material";
import {Loader} from "shared/ui/loader";
import {Outlet} from "react-router-dom";
import {Suspense} from "react";
import styles from './auth-layout.module.scss';
import {AuthLeftBar} from "widgets/auth-left-bar";
import {AuthRightBar} from "widgets/auth-right-bar";



export const AuthLayout = () => (
  <Container className={styles.authContainer} maxWidth={false} sx={
    {
      minHeight: '100vh',
      '&': {
        padding: 0,
      }
    }
  }>
    <AuthLeftBar>
      <Suspense fallback={<Loader />}>
        <Outlet />
      </Suspense>
    </AuthLeftBar>
    <AuthRightBar />
  </Container>
);
