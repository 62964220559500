import React, {useEffect} from 'react';
import styles from './otp.module.scss';
import {Box} from "@mui/material";
import { MuiOtpInput } from 'mui-one-time-password-input'
import {Link, useNavigate} from "react-router-dom";

export const Otp = () => {
  const resendMail = () => {
    setOtp('')
    alert('RESEND')
  }

  const [otp, setOtp] = React.useState('')
  let navigate = useNavigate();
  const handleChange = (newValue:string) => {
    setOtp(newValue)
  }

  useEffect(() => {
    if (otp.length > 5) {
      otp === '123456' ? navigate('/auth/restore_pass/new') : alert(otp);
    }
  }, [otp.length])

  return (
    <Box className={styles.loginPage}>
      <Box className={styles.loginTitleBlock}>
        <h2 className={styles.loginTitle}>
          <span className={styles.loginTitleBlack}>Обновите пароль</span>
        </h2>
        <span className={styles.loginDesc}>Введите код, который мы вам отправили на адрес <span className={styles.descBlue}>nikita.maksakov@gmail.com</span></span>
      </Box>
      <Box className={styles.loginForm}>
        <Box className={styles.loginInputBlock}>
          <Box className={styles.otpInputBack}>
            <MuiOtpInput className={styles.otpInput} value={otp} length={6} onChange={handleChange} sx={{
              '.MuiOutlinedInput-root': {
                borderRadius: '8px',
                // border: '1px solid #4794EF'
                backgroundColor: '#ECF3F6',
                width: '46px',
                height: '48px',
                fontSize: '24px',
                fontWeight: 500,
                lineHeight: '24px',
                letterSpacing: '0.15000000596046448px',
                color: '#001317',
              '&:hover': {
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: '#4794EF',
                  }
                },
                '&.Mui-focused': {
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: '#4794EF',
                    borderWidth: '1px',
                    boxShadow: '0px 0px 0px 4px #84DCF53D',

            }
                }
              },
              '&': {
                gap: '44px'
              },
              '.MuiOutlinedInput-notchedOutline': {
                borderColor: '#4794EF',
              }
            }} />
          </Box>
        </Box>
        <Box className={styles.otpButtonBlock}>
          <Link className={styles.otpBtn} to={'/auth/restore_pass/mail'}>Назад</Link>
          <div className={styles.otpBtn} onClick={resendMail}>Отправить код повторно</div>
        </Box>
      </Box>
    </Box>
  );
};
