import React, {useEffect, useState} from 'react';
import styles from './latest-app-admin.module.scss'
import {
  Box,
  IconButton,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import {Link, useNavigate} from "react-router-dom";
import clsx from "clsx";
import ArrowRight from "shared/assets/icons/arrow_right.svg";
import {apiClient} from "shared/api";

export const LatestAppAdmin = () => {


  let navigate = useNavigate();

  const [lastSelect, setLastSelect] = useState([])
  const [lastLeasing, setLastLeasing] = useState([])


  const getLastSelect = async () => {
    try {
      const response = await apiClient.get('select-applications/lastAdmin');
      setLastSelect(response.data && response.data.data);
    } catch (error) {
      console.error('Ошибка при получении данных: ', error);
    }
  };

  const getLastLeasing = async () => {
    try {
      const response = await apiClient.get('leasing-applications/lastAdmin');
      setLastLeasing(response.data && response.data.data);
    } catch (error) {
      console.error('Ошибка при получении данных: ', error);
    }
  };

  useEffect(()=>{
    getLastSelect();
    getLastLeasing();
  },[])

  const isApply = (status:string) => {
    return status == 'Подтверждена'
  }
  const isWaiting = (status:string) => {
    return status == 'Ожидает подтверждение'
  }

  const renderHeaderRow = (type:string) => {
    return (
      <TableRow className={styles.tableHeadRow}>
        <TableCell className={`${styles.tableHeadCellFirst} ${styles.tableHeadCell}`}>№ заявки</TableCell>
        <TableCell className={`${styles.tableHeadCell} ${styles.tableCellMobile}`} align="left">Марка</TableCell>
        <TableCell className={`${styles.tableHeadCell} ${styles.tableCellMobile}`} align="left">Модель</TableCell>
        <TableCell className={`${styles.tableHeadCell} ${styles.tableCellMobile}`} align="left">
          {type == 'select' ? 'Бюджет на покупку' : 'Стоимость лизинга'}
        </TableCell>
        <TableCell className={styles.tableHeadCell} align="left">Статус заявки</TableCell>
        <TableCell className={`${styles.tableHeadCellLast} ${styles.tableHeadCell}`} align="right"></TableCell>
      </TableRow>
    )
  }


  const renderRows = (rows:any, type:string) => {
    return (rows.map((row:any) => (
      <TableRow
        className={styles.tableBodyRow}
        key={row.id}
        sx={{ '& td, & th': { border: 0 }, marginTop: '10px' }}
      >
        <TableCell className={`${styles.tableBodyCellFirst} ${styles.tableBodyCell}`} component="th" scope="row">
          {row.id}
        </TableCell>
        <TableCell className={`${styles.tableBodyCell} ${styles.tableCellMobile}`} align="left">{row.car_brand}</TableCell>
        <TableCell className={`${styles.tableBodyCell} ${styles.tableCellMobile}`} align="left">{row.vehicle}</TableCell>
        <TableCell className={`${styles.tableBodyCell} ${styles.tableCellMobile}`} align="left">{new Intl.NumberFormat('ru-RU').format((row.price || row.budget) as number)} ₽</TableCell>
        <TableCell
          className={clsx([`${styles.tableBodyCell}`, isApply(row.status_admin) && `${styles.apply}`, isWaiting(row.status_admin) && `${styles.waiting}`])}
          align="left"
        >
          {row.status_admin}
        </TableCell>
        <TableCell className={`${styles.tableBodyCellLast} ${styles.tableBodyCell}`} align="right">
          <IconButton
            disableRipple
            sx={{
              width: '40px',
              height: '40px'
            }}
            onClick={()=> navigate(`/dashboard/admin/app/${type}/${row.documentId}`)}
            edge="end"
            className={styles.svgIcon}
          >
            <SvgIcon component={ArrowRight} inheritViewBox sx={{fontSize:'18px'}} />
          </IconButton>
        </TableCell>
      </TableRow>
    )))
  }




  return (
    <Box className={styles.latestApp}>
      <Box className={styles.latestCardWrapper}>
        <Box className={styles.latestCardHeader}>
          <span className={styles.latestHeaderTitle}>Заявки на подбор транспортного средства</span>
          <Link className={styles.latestHeaderLink} to={'/dashboard/admin/all/select'}>Смотреть все</Link>
        </Box>
        <Box className={styles.latestAppTable}>
          <TableContainer component={Box} className={styles.tableContainer}>
            <Table className={styles.table} sx={{ minWidth: 1070 }} aria-label="Заявки на подбор транспортного средства">
              <TableHead className={styles.tableHead}>
                {renderHeaderRow('select')}
              </TableHead>
              <TableBody className={styles.tableBody}>
                {renderRows(lastSelect, 'select')}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>

      <Box className={styles.latestCardWrapper}>
        <Box className={styles.latestCardHeader}>
          <span className={styles.latestHeaderTitle}>Заявки на лизинг</span>
          <Link className={styles.latestHeaderLink} to={'/dashboard/admin/all/leasing'}>Смотреть все</Link>
        </Box>
        <Box className={styles.latestAppTable}>
          <TableContainer component={Box} className={styles.tableContainer}>
            <Table className={styles.table} sx={{ minWidth: 1070 }} aria-label="Заявки на лизинг">
              <TableHead className={styles.tableHead}>
                {renderHeaderRow('leasing')}
              </TableHead>
              <TableBody className={styles.tableBody}>
                {renderRows(lastLeasing, 'leasing')}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Box>
  );
};
