import React from 'react';
import styles from "./download-button.module.scss";
import { Box, BoxProps, SvgIcon } from "@mui/material";
import FileIcon from 'shared/assets/icons/file.svg';
import DownloadIcon from 'shared/assets/icons/download.svg';
import {serverUrl} from "shared/api";

interface DownloadButtonProps extends BoxProps {
  title: string;
  fileUrl: string;
}

export const DownloadButton = (props: DownloadButtonProps) => {
  const handleDownload = async () => {
    try {
      const downloadUrl = `${serverUrl}${props.fileUrl}`;

      const response = await fetch(downloadUrl);
      if (!response.ok) {
        throw new Error('Не удалось скачать файл');
      }
      const blob = await response.blob();
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = props.title;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error('Ошибка при скачивании файла:', error);
    }
  };

  return (
    <Box className={styles.downloadBtn} onClick={handleDownload}>
      <SvgIcon component={FileIcon} inheritViewBox sx={{ fontSize: '20px' }} />
      <span>{props.title}</span>
      <SvgIcon className={styles.downBtnIcon} component={DownloadIcon} inheritViewBox sx={{ fontSize: '16px' }} />
    </Box>
  );
};
