import React from 'react';
import {Link} from "react-router-dom";
import logo from "shared/assets/images/logo.png";

export const LogoLink = () => {
  return (
    <Link to={'/'}>
      <img src={logo} alt="TLS Group"/>
    </Link>
  );
};
