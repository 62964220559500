export enum RouteNames {
  MAIN = 'MAIN',
  PRODUCT = 'PRODUCT',
  DASHBOARD = 'DASHBOARD',
  AUTH = 'AUTH',
  RESTORE_PASS = 'RESTORE_PASS',
  OTP = 'OTP',
  NEW_PASS = 'NEW_PASS',
  REGISTER_AGENT = 'REGISTER_AGENT',
  APPLICATION = 'APPLICATION',
  ALL_APP = 'ALL_APP',
  LAST_APP = 'LAST_APP',
  NEW_APP = 'NEW_APP',
  OPEN_APP = 'OPEN_APP',
  OPEN_OFFER = 'OPEN_OFFER',
  ADMIN_LAST = 'ADMIN_LAST',
  ADMIN_ALL = 'ADMIN_ALL',
  ADMIN_APP = 'ADMIN_APP',
  ADMIN_OFFER = 'ADMIN_OFFER',
  ADMIN_AGENTS = 'ADMIN_AGENTS',
  FULL_OFFER = 'FULL_OFFER',
  FULL_APP = 'FULL_APP',
}

export const ROUTE_PATHS: Record<RouteNames, string> = {
  [RouteNames.MAIN]: '/',

  [RouteNames.PRODUCT]: '/product/:product',

  [RouteNames.AUTH]: '/auth',
  [RouteNames.RESTORE_PASS]: '/auth/restore_pass/mail',
  [RouteNames.OTP]: '/auth/restore_pass/otp',
  [RouteNames.NEW_PASS]: '/auth/restore_pass/new',
  [RouteNames.REGISTER_AGENT]: '/auth/registry',

  [RouteNames.DASHBOARD]: '/dashboard',
  [RouteNames.ALL_APP]: '/dashboard/all/:type',
  [RouteNames.LAST_APP]: '/dashboard/last',
  [RouteNames.NEW_APP]: '/dashboard/new/:type',
  [RouteNames.OPEN_APP]: '/dashboard/app/:type/:uid',
  [RouteNames.OPEN_OFFER]: '/dashboard/offer/:type/:uid',

  [RouteNames.ADMIN_LAST]: '/dashboard/admin/last',
  [RouteNames.ADMIN_ALL]: '/dashboard/admin/all/:type',
  [RouteNames.ADMIN_APP]: '/dashboard/admin/app/:type/:uid',
  [RouteNames.ADMIN_OFFER]: '/dashboard/admin/offer/:type/:uid',
  [RouteNames.ADMIN_AGENTS]: '/dashboard/admin/agents',

  [RouteNames.APPLICATION]: '/app',
  [RouteNames.FULL_APP]: '/app/:type/:uid/:oid',
  [RouteNames.FULL_OFFER]: '/app/full/:type/:uid/:oid',

};
