import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import {Loader} from 'shared/ui/loader';
import styles from './app-layout.module.scss';
import {MainHeader} from "widgets/main-header";
import {MainFooter} from "widgets/main-footer";
import {Container} from "@mui/material";

export const AppLayout = () => (
    <Container
      className={styles.appContainer}
      maxWidth={false}
      sx={
        {
          maxWidth: '1440px',
          minHeight: '100vh',
          '&': {
            paddingLeft: '20px',
            paddingRight: '20px',
          }
        }}
    >
      <MainHeader />
      <Suspense fallback={<Loader />}>
        <Outlet />
      </Suspense>
      <MainFooter />
    </Container>
);
