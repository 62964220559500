import React, {useEffect, useState} from 'react';
import styles from './all-app-admin.module.scss'
import {
  Box,
  IconButton,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import {DashboardHeaderBackLink} from "widgets/dashboard-header-back-link";
import {useNavigate, useParams} from "react-router-dom";
import clsx from "clsx";
import ArrowRight from "shared/assets/icons/arrow_right.svg";
import {apiClient} from "shared/api";

export const AllAppAdmin = () => {

  let navigate = useNavigate();
  const {type} = useParams();

  const title = type == 'select' ? 'Заявки на подбор транспортного средства' : 'Заявки на лизинг'
  const headerTitlePriceText = type == 'select' ? 'Бюджет на покупку' : 'Стоимость лизинга'

  const url = type == 'select' ? '/select-applications/allAdmin' : '/leasing-applications/allAdmin'

  const [allApp, setAllApp] = useState([]);

  const getAllApp = async () => {
    try {
      const response = await apiClient.get(url);
      setAllApp(response.data && response.data.data);
    } catch (error) {
      console.error('Ошибка при получении данных: ', error);
    }
  };

  useEffect(() => {
    getAllApp();
  }, []);

  const isApply = (status:string) => {
    return status == 'Подтверждена'
  }
  const isWaiting = (status:string) => {
    return status == 'Ожидает подтверждение'
  }

  const renderHeaderRow = () => {
    return (
      <TableRow className={styles.tableHeadRow}>
        <TableCell className={`${styles.tableHeadCellFirst} ${styles.tableHeadCell}`}>№ заявки</TableCell>
        <TableCell className={`${styles.tableHeadCell} ${styles.tableCellMobile}`} align="left">Марка</TableCell>
        <TableCell className={`${styles.tableHeadCell} ${styles.tableCellMobile}`} align="left">Модель</TableCell>
        <TableCell className={`${styles.tableHeadCell} ${styles.tableCellMobile}`} align="left">{headerTitlePriceText}</TableCell>
        <TableCell className={styles.tableHeadCell} align="left">Статус заявки</TableCell>
        <TableCell className={`${styles.tableHeadCellLast} ${styles.tableHeadCell}`} align="right"></TableCell>
      </TableRow>
    )
  }


  const renderRows = (rows:any) => {
    return (rows.map((row:any) => (
      <TableRow
        className={styles.tableBodyRow}
        key={row.id}
        sx={{ '& td, & th': { border: 0 }, marginTop: '10px' }}
      >
        <TableCell className={`${styles.tableBodyCellFirst} ${styles.tableBodyCell}`} component="th" scope="row">
          {row.id}
        </TableCell>
        <TableCell className={`${styles.tableBodyCell} ${styles.tableCellMobile}`} align="left">{row.car_brand}</TableCell>
        <TableCell className={`${styles.tableBodyCell} ${styles.tableCellMobile}`} align="left">{row.vehicle}</TableCell>
        <TableCell className={`${styles.tableBodyCell} ${styles.tableCellMobile}`} align="left">{new Intl.NumberFormat('ru-RU').format((row.price || row.budget) as number)} ₽</TableCell>
        <TableCell
          className={clsx([`${styles.tableBodyCell}`, isApply(row.status_admin) && `${styles.apply}`, isWaiting(row.status_admin) && `${styles.waiting}`])}
          align="left"
        >
          {row.status_admin}
        </TableCell>
        <TableCell className={`${styles.tableBodyCellLast} ${styles.tableBodyCell}`} align="right">
          <IconButton
            disableRipple
            sx={{
              width: '40px',
              height: '40px'
            }}
            onClick={()=> navigate(`/dashboard/admin/app/${type}/${row.documentId}`)}
            edge="end"
            className={styles.svgIcon}
          >
            <SvgIcon component={ArrowRight} inheritViewBox sx={{fontSize:'18px'}} />
          </IconButton>
        </TableCell>
      </TableRow>
    )))
  }

  return (
    <Box className={styles.allPage}>
      <DashboardHeaderBackLink link={'/dashboard/admin/last'} title={title} />
      <Box>
        <TableContainer component={Box} className={styles.tableContainer}>
          <Table className={styles.table} sx={{ minWidth: 1070 }} aria-label={title}>
            <TableHead className={styles.tableHead}>
              {renderHeaderRow()}
            </TableHead>
            <TableBody className={styles.tableBody}>
              {renderRows(allApp)}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};
