import React from 'react';
import styles from './new-pass.module.scss';
import {Box} from "@mui/material";
import {PassInput} from "shared/ui/pass-input";
import {ButtonCustom} from "shared/ui/button-custom";

export const NewPass = () => {
  return (
    <Box className={styles.loginPage}>
      <Box className={styles.loginTitleBlock}>
        <h2 className={styles.loginTitle}>
          <span className={styles.loginTitleBlack}>Создайте новый пароль</span>
        </h2>
        <span className={styles.loginDesc}>Введите новый пароль для входа в систему.</span>
      </Box>
      <Box className={styles.loginForm}>
        <Box className={styles.loginInputBlock}>
          <Box className={styles.loginInputBack}>
            <PassInput placeholder={'Введите новый пароль'} />
          </Box>
          <Box className={styles.loginInputBack}>
            <PassInput placeholder={'Повторите новый пароль'} />
          </Box>
        </Box>
        <Box className={styles.submitBtn}>
          <ButtonCustom>
            Создать новый пароль
          </ButtonCustom>
        </Box>
      </Box>
    </Box>
  );
};
