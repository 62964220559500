import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import {Loader} from 'shared/ui/loader';
import styles from './mail-layout.module.scss';
import {Container} from "@mui/material";
import {MailLeftBar} from "widgets/mail-left-bar";

export const MailLayout = () => (
  <Container
    className={styles.dashboardContainer}
    maxWidth={false}
    sx={
      {
        maxWidth: '1440px',
        minHeight: '100vh',
        '&': {
          padding: '20px 0 24px 0',
        }
      }}
  >
    <MailLeftBar />
    <Suspense fallback={<Loader />}>
      <Outlet />
    </Suspense>
  </Container>
);
