import {Box, IconButton, SvgIcon} from "@mui/material";
import {Link} from "react-router-dom";
import LoginIcon from 'shared/assets/icons/login.svg';
import styles from './main-header.module.scss';
import { HashLink } from 'react-router-hash-link';
import {LogoLink} from "shared/ui/logo-link";
import CloseIcon from "shared/assets/icons/close.svg";
import MenuIcon from "shared/assets/icons/menu.svg";
import React from "react";
import clsx from "clsx";
export const MainHeader = () => {

  const user = ''
  const [open, setOpen] = React.useState(false);

  const url = user ? '/dashboard' : '/auth/';

  const menuToggle = () => {
    setOpen(!open);
  }

  return (

  <Box>
    <Box className={styles.headerContainer} component="header">
      <LogoLink />
      <Box component='div' className={styles.navbarWrapper}>
        <Box component='div' className={styles.navbar}>
          <HashLink className={styles.navbarLink} smooth to={'/#selected'}>Подбор ТС</HashLink>
          <HashLink className={styles.navbarLink} smooth to={'/#catalog'}>Каталог</HashLink>
          <HashLink className={styles.navbarLink} smooth to={'/#leasing'}>Лизинг</HashLink>
          <HashLink className={styles.navbarLink} smooth to={'/#contacts'}>Контакты</HashLink>
        </Box>
        <Link to={url} className={styles.loginLink}>
          <div className={styles.loginIcon}>
            <LoginIcon />
          </div>
          <span>Войти в личный кабинет</span>
        </Link>
      </Box>

      <Box className={styles.mobileHamburger}>
        <IconButton
          disableRipple
          sx={{
            width: '40px',
            height: '40px'
          }}
          onClick={() => menuToggle()}
          edge="end"
          className={styles.svgIcon}
        >
          {open ?
            <SvgIcon component={CloseIcon} inheritViewBox sx={{fontSize:'18px'}} /> :
            <SvgIcon component={MenuIcon} inheritViewBox sx={{fontSize:'18px'}} />
          }
        </IconButton>
      </Box>
    </Box>
    <Box className={clsx([`${styles.mobileMenu}`, open && `${styles.open}`])}>
      <Box component='div' className={styles.navbarMobile}>
        <HashLink className={styles.mobileMenuLink} smooth to={'/#selected'}>Подбор ТС</HashLink>
        <HashLink className={styles.mobileMenuLink} smooth to={'/#catalog'}>Каталог</HashLink>
        <HashLink className={styles.mobileMenuLink} smooth to={'/#leasing'}>Лизинг</HashLink>
        <HashLink className={styles.mobileMenuLink} smooth to={'/#contacts'}>Контакты</HashLink>
      </Box>
      <Link to={url} className={styles.loginLink}>
        <div className={styles.loginIcon}>
          <LoginIcon />
        </div>
        <span>Войти в личный кабинет</span>
      </Link>
    </Box>
  </Box>
  );
};
