import { StrictMode } from 'react';
import { RouterProvider } from 'app/providers/router/router-provider';
import 'app/styles/index.scss';
import {createTheme, CssBaseline, ThemeProvider} from "@mui/material";

import GilroyRegular from 'shared/assets/fonts/Gilroy-Regular.ttf'
import GilroyMedium from 'shared/assets/fonts/Gilroy-Medium.ttf'
import GilroySemiBold from 'shared/assets/fonts/Gilroy-SemiBold.ttf'
import GilroyBold from 'shared/assets/fonts/Gilroy-Bold.ttf'

let theme = createTheme({
  typography: {
    fontFamily: 'Gilroy, sans-serif',
    fontSize: 18,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides:
        `
          @font-face {
            font-family: 'Gilroy';
            font-style: normal;
            font-display: swap;
            font-weight: 400;
            src: url(${GilroyRegular}) format('truetype');
            unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
  
          }
          @font-face {
            font-family: 'Gilroy';
            font-style: normal;
            font-display: swap;
            font-weight: 500;
            src: url(${GilroyMedium}) format('truetype');
            unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
          }
          @font-face {
            font-family: 'Gilroy';
            font-style: normal;
            font-display: swap;
            font-weight: 600;
            src: url(${GilroySemiBold}) format('truetype');
            unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
          }
          @font-face {
            font-family: 'Gilroy';
            font-style: normal;
            font-display: swap;
            font-weight: 700;
            src: url(${GilroyBold}) format('truetype');
            unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
          }
          body {
            background-color: #ECF3F6;
            color: #001317;
          }
        `
    },
  },
});

export const App = () => (
    <StrictMode>
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <RouterProvider />
        </ThemeProvider>
    </StrictMode>
);
