import React, {useEffect, useState} from 'react';
import styles from './open-application.module.scss'
import {useNavigate, useParams} from "react-router-dom";
import {
  Box,
  IconButton,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import {DashboardHeaderBackLink} from "widgets/dashboard-header-back-link";
import {DownloadButton} from "shared/ui/download-button";
import clsx from "clsx";
import ArrowRight from "shared/assets/icons/arrow_right.svg";
import {apiClient} from "shared/api";

export const OpenApplication = () => {
  const {type, uid} = useParams()
  let navigate = useNavigate()

  const [appData, setAppData] = useState<any>([]);
  const [rowsSelect, setRowsSelect] = useState<any>([]);
  const [rowsLeasing, setRowsLeasing] = useState<any>([]);
  const [dynamicField, setDynamicField] = useState<any>([]);

  const postData = async () => {
    try {
      const data = { id: uid };
      const url = type == 'select' ? '/select-applications/app' : '/leasing-applications/app';
      const response = await apiClient.post(url, data);
      setAppData(response?.data?.data?.application);

    } catch (error) {
      console.error('Ошибка при отправке данных:', error);
    }
  };

  useEffect(() => {
    postData();
  },[])

  useEffect(() => {
    setDynamicField(appData?.car_params || [])
    if (type == 'select') {
      setRowsSelect(appData?.select_offers || [])
    } else {
      setRowsLeasing(appData?.leasing_offers || [])
    }
  },[appData])

  const renderApp = () => {
    if (type == 'select') {
      return (
        <Box className={styles.appWrapper}>
          <Box className={styles.appDesc}>
            <span className={styles.appDescTitle}>Состояние</span>
            <span className={styles.appDescValue}>{appData?.condition}</span>
          </Box>
          <Box className={styles.appDesc}>
            <span className={styles.appDescTitle}>Cпособ покупки</span>
            <span className={styles.appDescValue}>{appData?.pay_method}</span>
          </Box>
          <Box className={styles.appDesc}>
            <span className={styles.appDescTitle}>Количество ТС</span>
            <span className={styles.appDescValue}>{appData?.quantity}</span>
          </Box>
          <Box className={styles.appDesc}>
            <span className={styles.appDescTitle}>Траснспортное средство</span>
            <span className={styles.appDescValue}>{appData?.car_subtype}</span>
          </Box>
          <Box className={styles.appDesc}>
            <span className={styles.appDescTitle}>Марка</span>
            <span className={styles.appDescValue}>{appData?.car_brand}</span>
          </Box>
          <Box className={styles.appDesc}>
            <span className={styles.appDescTitle}>Модель</span>
            <span className={styles.appDescValue}>{appData?.vehicle}</span>
          </Box>

          {dynamicField?.map((field: any) => (
            <Box key={field?.fieldName} className={styles.appDesc}>
              <span className={styles.appDescTitle}>{field?.fieldName}</span>
              <span className={styles.appDescValue}>{field?.fieldValue}</span>
            </Box>
          ))}

          <Box className={styles.appDesc}>
            <span className={styles.appDescTitle}>Бюджет на покупку</span>
            <span className={styles.appDescValue}>{new Intl.NumberFormat('ru-RU').format((appData?.budget) as number)} ₽</span>
          </Box>

          {appData?.inn && (
            <Box className={styles.appDesc}>
              <span className={styles.appDescTitle}>ИНН</span>
              <span className={styles.appDescValue}>{appData?.inn}</span>
            </Box>
          )}

          {appData?.phone && (
            <Box className={styles.appDesc}>
              <span className={styles.appDescTitle}>Телефон</span>
              <span className={styles.appDescValue}>{appData?.phone}</span>
            </Box>
          )}

          {appData?.email && (
            <Box className={styles.appDesc}>
              <span className={styles.appDescTitle}>E-mail</span>
              <span className={styles.appDescValue}>{appData?.email}</span>
            </Box>
          )}

          {appData?.bio && (
            <Box className={styles.appDesc}>
              <span className={styles.appDescTitle}>ФИО</span>
              <span className={styles.appDescValue}>{appData?.bio}</span>
            </Box>
          )}

          {appData?.kp && (
            <Box className={styles.appDesc}>
              <span className={styles.appDescTitle}>Имеющееся предложение</span>
              <DownloadButton fileUrl={appData?.kp?.url} title={appData?.kp?.name} />
            </Box>
          )}
        </Box>
      )
    }
    if (type == 'leasing') {
      return (
        <Box className={styles.appWrapper}>
          <Box className={styles.appDesc}>
            <span className={styles.appDescTitle}>Траснспортное средство</span>
            <span className={styles.appDescValue}>{appData?.car_subtype}</span>
          </Box>
          <Box className={styles.appDesc}>
            <span className={styles.appDescTitle}>Марка</span>
            <span className={styles.appDescValue}>{appData?.car_brand}</span>
          </Box>
          <Box className={styles.appDesc}>
            <span className={styles.appDescTitle}>Модель</span>
            <span className={styles.appDescValue}>{appData?.vehicle}</span>
          </Box>


          <Box className={styles.appDesc}>
            <span className={styles.appDescTitle}>Количество ТС</span>
            <span className={styles.appDescValue}>{appData?.quantity}</span>
          </Box>


          <Box className={styles.appDesc}>
            <span className={styles.appDescTitle}>Стоимость лизинга</span>
            <span className={styles.appDescValue}>{new Intl.NumberFormat('ru-RU').format((appData?.price) as number)} ₽</span>
          </Box>
          <Box className={styles.appDesc}>
            <span className={styles.appDescTitle}>Первоначальный взнос</span>
            <span className={styles.appDescValue}>{appData?.initial_payment} %</span>
          </Box>
          <Box className={styles.appDesc}>
            <span className={styles.appDescTitle}>Срок лизинга</span>
            <span className={styles.appDescValue}>{appData?.lease_period} мес.</span>
          </Box>

          {appData?.inn && (
            <Box className={styles.appDesc}>
              <span className={styles.appDescTitle}>ИНН</span>
              <span className={styles.appDescValue}>{appData?.inn}</span>
            </Box>
          )}

          {appData?.phone && (
            <Box className={styles.appDesc}>
              <span className={styles.appDescTitle}>Телефон</span>
              <span className={styles.appDescValue}>{appData?.phone}</span>
            </Box>
          )}

          {appData?.email && (
            <Box className={styles.appDesc}>
              <span className={styles.appDescTitle}>E-mail</span>
              <span className={styles.appDescValue}>{appData?.email}</span>
            </Box>
          )}

          {appData?.bio && (
            <Box className={styles.appDesc}>
              <span className={styles.appDescTitle}>ФИО</span>
              <span className={styles.appDescValue}>{appData?.bio}</span>
            </Box>
          )}

          {appData?.kp && (
            <Box className={styles.appDesc}>
              <span className={styles.appDescTitle}>Имеющееся предложение</span>
              <DownloadButton fileUrl={appData?.kp?.url} title={appData?.kp?.name} />
            </Box>
          )}

        </Box>
      )
    }
  }

  const isStock = (status:string) => {
    return status == 'В наличии'
  }
  const isOrder = (status:string) => {
    return status == 'Под заказ'
  }

  const renderHeaderRowSelect = () => {
    return (
      <TableRow className={styles.tableHeadRow}>
        <TableCell className={`${styles.tableHeadCellFirst} ${styles.tableHeadCell} ${styles.tableCellSmall}`} align="left">Транспортное средство</TableCell>
        <TableCell className={`${styles.tableHeadCell} ${styles.tableCellSmall}`} align="left">Марка</TableCell>
        <TableCell className={`${styles.tableHeadCell} ${styles.tableCellSmall}`} align="left">Модель</TableCell>
        <TableCell className={`${styles.tableHeadCell} ${styles.tableFirstMob}`} align="left">Стоимость</TableCell>
        <TableCell className={styles.tableHeadCell} align="left">Наличие</TableCell>
        <TableCell className={`${styles.tableHeadCell} ${styles.tableCellMobile}`} align="left">Срок поставки</TableCell>
        <TableCell className={`${styles.tableHeadCellLast} ${styles.tableHeadCell}`} align="right"></TableCell>
      </TableRow>
    )
  }

  const renderHeaderRowLeasing = () => {
    return (
      <TableRow className={styles.tableHeadRow}>
        <TableCell className={`${styles.tableHeadCellFirst} ${styles.tableHeadCell} ${styles.tableCellSmall}`} align="left">Транспортное средство</TableCell>
        <TableCell className={`${styles.tableHeadCell} ${styles.tableCellSmall}`} align="left">Марка</TableCell>
        <TableCell className={`${styles.tableHeadCell} ${styles.tableCellSmall}`} align="left">Модель</TableCell>
        <TableCell className={`${styles.tableHeadCell} ${styles.tableFirstMob}`} align="left">Стоимость</TableCell>
        <TableCell className={`${styles.tableHeadCell} ${styles.tableCellMobile}`} align="left">Первоначальный взнос</TableCell>
        <TableCell className={styles.tableHeadCell} align="left">Срок лизинга</TableCell>
        <TableCell className={`${styles.tableHeadCellLast} ${styles.tableHeadCell}`} align="right"></TableCell>
      </TableRow>
    )
  }


  const renderRowsSelect = (rows:any) => {
    const filteredRows = rows.filter((row: any) => row.status_filled === true);
    return (filteredRows.map((row:any) => (
      <TableRow
        className={clsx([`${styles.tableBodyRow}`, row.status_accept && `${styles.selectedRow}`])}
        key={row.documentId}
        sx={{ '& td, & th': { border: 0 }, marginTop: '10px' }}
      >
        <TableCell className={`${styles.tableBodyCellFirst} ${styles.tableBodyCell}  ${styles.tableCellSmall}`} component="th" scope="row">
          {row.car_subtype}
        </TableCell>
        <TableCell className={`${styles.tableBodyCell} ${styles.tableCellSmall}`} align="left">{row.car_brand}</TableCell>
        <TableCell className={`${styles.tableBodyCell} ${styles.tableCellSmall}`} align="left">{row.car_model}</TableCell>
        <TableCell className={`${styles.tableBodyCell} ${styles.tableFirstMob}`} align="left">{new Intl.NumberFormat('ru-RU').format(row.price as number)} ₽</TableCell>
        <TableCell
          className={clsx([`${styles.tableBodyCell}`, isStock(row.availability) && `${styles.stock}`, isOrder(row.availability) && `${styles.order}`])}
          align="left"
        >
          {row.availability}
        </TableCell>
        <TableCell className={`${styles.tableBodyCell} ${styles.tableCellMobile}`} align="left">{row.delivery_time}</TableCell>
        <TableCell className={`${styles.tableBodyCellLast} ${styles.tableBodyCell}`} align="right">
          <IconButton
            disableRipple
            sx={{
              width: '40px',
              height: '40px'
            }}
            onClick={()=> navigate(`/dashboard/offer/select/${row.documentId}`)}
            edge="end"
            className={styles.svgIcon}
          >
            <SvgIcon component={ArrowRight} inheritViewBox sx={{fontSize:'18px'}} />
          </IconButton>
        </TableCell>
      </TableRow>
    )))
  }

  const renderRowsLeasing = (rows:any) => {
    const filteredRows = rows.filter((row: any) => row.status_filled === true);
    return (filteredRows.map((row:any) => (
      <TableRow
        className={clsx([`${styles.tableBodyRow}`, row.status_accept && `${styles.selectedRow}`])}
        key={row.documentId}
        sx={{ '& td, & th': { border: 0 }, marginTop: '10px' }}
      >
        <TableCell className={`${styles.tableBodyCellFirst} ${styles.tableBodyCell} ${styles.tableCellSmall}`} component="th" scope="row">
          {row.car_subtype}
        </TableCell>
        <TableCell className={`${styles.tableBodyCell} ${styles.tableCellSmall}`} align="left">{row.car_brand}</TableCell>
        <TableCell className={`${styles.tableBodyCell} ${styles.tableCellSmall}`} align="left">{row.vehicle}</TableCell>
        <TableCell className={`${styles.tableBodyCell} ${styles.tableFirstMob}`} align="left">{new Intl.NumberFormat('ru-RU').format(row.price as number)} ₽</TableCell>
        <TableCell className={`${styles.tableBodyCell} ${styles.tableCellMobile}`} align="left">{row.initial_payment} %</TableCell>
        <TableCell className={styles.tableBodyCell} align="left">{row.lease_period} мес.</TableCell>
        <TableCell className={`${styles.tableBodyCellLast} ${styles.tableBodyCell}`} align="right">
          <IconButton
            disableRipple
            sx={{
              width: '40px',
              height: '40px'
            }}
            onClick={()=> navigate(`/dashboard/offer/leasing/${row.documentId}`)}
            edge="end"
            className={styles.svgIcon}
          >
            <SvgIcon component={ArrowRight} inheritViewBox sx={{fontSize:'18px'}} />
          </IconButton>
        </TableCell>
      </TableRow>
    )))
  }


  return (
    <Box className={styles.openApp}>
      <DashboardHeaderBackLink link={-1} title={`Заявка № ${appData.id}`} />
      {renderApp()}
      <Box className={styles.offerTableBlock}>
        <span className={styles.offerHeader}>Предложения по заявке</span>
        <Box>
          <TableContainer component={Box} className={styles.tableContainer}>
            <Table className={styles.table} sx={{ minWidth: 1070 }} aria-label={uid}>
              <TableHead className={styles.tableHead}>
                {type == 'select' ? renderHeaderRowSelect() : renderHeaderRowLeasing()}
              </TableHead>
              <TableBody className={styles.tableBody}>
                {type == 'select' ? renderRowsSelect(rowsSelect) : renderRowsLeasing(rowsLeasing)}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Box>
  );
};
