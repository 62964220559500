import React from 'react';
import styles from './mail-left-bar.module.scss'
import {Box} from "@mui/material";
import {LogoLink} from "shared/ui/logo-link";

export const MailLeftBar = () => {

  return (
    <Box className={styles.leftBar}>
      <LogoLink />
    </Box>
  );
};
