import React, { useEffect, useState } from 'react';
import styles from './dashboard-left-bar.module.scss';
import { Box, IconButton, SvgIcon } from '@mui/material';
import { LogoLink } from 'shared/ui/logo-link';
import LogoutIcon from 'shared/assets/icons/logout.svg';
import AgentsIcon from 'shared/assets/icons/agents.svg';
import { useNavigate, useLocation } from 'react-router-dom';
import { getUser } from 'shared/api';

interface User {
  bio: string;
  email: string;
}

export const DashboardLeftBar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const fetchUser = async () => {
      const userData = await getUser();
      setUser(userData);
    };
    fetchUser();
  }, []);

  const logout = () => {
    localStorage.removeItem('token');
    sessionStorage.removeItem('token');
    navigate('/auth')
  };

  const isAdmin = location.pathname.includes('/admin');

  return (
      <Box className={styles.leftBar}>
        <LogoLink />

        {isAdmin && (
            <Box
                className={styles.agentsLink}
                onClick={() => navigate('/dashboard/admin/agents')}
            >
              <SvgIcon
                  component={AgentsIcon}
                  className={styles.svgLogout}
                  inheritViewBox
                  sx={{ fontSize: '18px' }}
              />
              <span className={styles.spanText}>Агенты</span>
            </Box>
        )}

        {user && (
            <Box className={styles.userBlock}>
              <Box className={styles.userInfo}>
                <span className={styles.userName}>{user.bio}</span>
                <span className={styles.userMail}>{user.email}</span>
              </Box>
              <IconButton
                  onClick={logout}
                  sx={{ width: '20px', height: '20px' }}
                  className={styles.logoutBtn}
                  edge="end"
                  disableRipple
              >
                <SvgIcon
                    component={LogoutIcon}
                    className={styles.svgLogout}
                    inheritViewBox
                    sx={{ fontSize: '19px' }}
                />
              </IconButton>
            </Box>
        )}
      </Box>
  );
};
