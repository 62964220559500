import styles from './file-input-custom.module.scss'
import {Button, ButtonProps, styled} from "@mui/material";
import {useState} from "react";

interface FileInputProps extends ButtonProps {
  text?: string;
}

export const FileInputCustom = ({text, ...props}:FileInputProps) => {
  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  const [title, setTitle] = useState(text)

  const changeTitle = (event:any) => {
    if (event.target.files[0]) {
      setTitle(event.target?.files[0].name)
    } else {
      setTitle(text)
    }
  }


  return (
    <Button
      className={styles.customFileInput}
      variant="outlined"
      fullWidth
      disableRipple
      component="label"
      {...props}
    >
      <span className={styles.text}>{title}</span>
      <VisuallyHiddenInput
        type="file"
        onChange={(event) => changeTitle(event)}
        multiple={false}
      />
    </Button>
  );
};
