import React, {useEffect, useState} from 'react';
import styles from './offer-admin.module.scss'
import {Box} from "@mui/material";
import {useParams} from "react-router-dom";
import {DownloadButton} from "shared/ui/download-button";
import {DashboardHeaderBackLink} from "widgets/dashboard-header-back-link";
import clsx from "clsx";
import {ButtonCustom} from "shared/ui/button-custom";
import {apiClient} from "shared/api";

export const OfferAdmin = () => {
  const {type, uid} = useParams()

  const [dynamicField, setDynamicField] = useState<any>([]);
  const [appData, setAppData] = useState<any>([]);
  const [offerData, setOfferData] = useState<any>([]);
  const [acceptedDisplay, setAcceptedDisplay] = useState<any>(false);
  const [loading, setLoading] = useState<any>(false);

  const postDataOffer = async () => {
    try {
      const data = { id: uid };
      const url = type == 'select' ? '/select-offers/offerAdmin' : '/leasing-offers/offerAdmin';

      const responseOffer = await apiClient.post(url, data);
      setOfferData(responseOffer?.data?.data?.offer);

    } catch (error) {
      console.error('Ошибка при отправке данных:', error);
    }
  };

  const hasAcceptedOffer = (app:any) => {
    if (type == 'select') {
      if (!app || !Array.isArray(app.select_offers)) {
        return false;
      }
      return app.select_offers.some((offer:any) => offer.status_admin_approve === true);
    }
    if (type == 'leasing') {
      if (!app || !Array.isArray(app.leasing_offers)) {
        return false;
      }
      return app.leasing_offers.some((offer:any) => offer.status_admin_approve === true);
    }
  }

  const postDataApp = async () => {
    try {
      if (type == 'select') {
        const data = { id: offerData?.select_application?.documentId };
        const responseApp = await apiClient.post('/select-applications/appAdmin', data);
        setAppData(responseApp?.data?.data?.application);
      } else {
        const data = { id: offerData?.leasing_application?.documentId };
        const responseApp = await apiClient.post('/leasing-applications/appAdmin', data);
        setAppData(responseApp?.data?.data?.application);
      }

    } catch (error) {
      console.error('Ошибка при отправке данных:', error);
    }
  };

  useEffect(() => {
    postDataOffer();
  },[])

  useEffect(() => {
    if (offerData?.leasing_application?.documentId || offerData?.select_application?.documentId) {
      postDataApp();
    }
  },[offerData])

  useEffect(() => {
    if (appData.select_offers || appData.leasing_offers) {
      setAcceptedDisplay(!(hasAcceptedOffer(appData)))
    }
    setDynamicField(appData?.car_params || [])
  },[appData])

  const isStock = (status:string) => {
    return status == 'В наличии'
  }
  const isOrder = (status:string) => {
    return status == 'Под заказ'
  }

  const handleAcceptOffer = async () => {
    try {
      setLoading(true)
      const data = { id: uid };
      const url = type == 'select' ? '/select-offers/acceptAdmin' : '/leasing-offers/acceptAdmin';

      const responseOffer = await apiClient.post(url, data);
      setAcceptedDisplay(false);

    } catch (error) {
      console.error('Ошибка при отправке данных:', error);
      setAcceptedDisplay(true);
    } finally {
      setLoading(false)
    }
  };

  const renderApp = () => {
    if (type == 'select') {
      return (
        <Box className={styles.appWrapper}>
          <Box className={styles.appDesc}>
            <span className={styles.appDescTitle}>Состояние</span>
            <span className={styles.appDescValue}>{appData?.condition}</span>
          </Box>
          <Box className={styles.appDesc}>
            <span className={styles.appDescTitle}>Cпособ покупки</span>
            <span className={styles.appDescValue}>{appData?.pay_method}</span>
          </Box>
          <Box className={styles.appDesc}>
            <span className={styles.appDescTitle}>Количество ТС</span>
            <span className={styles.appDescValue}>{appData?.quantity}</span>
          </Box>
          <Box className={styles.appDesc}>
            <span className={styles.appDescTitle}>Траснспортное средство</span>
            <span className={styles.appDescValue}>{appData?.car_subtype}</span>
          </Box>
          <Box className={styles.appDesc}>
            <span className={styles.appDescTitle}>Марка</span>
            <span className={styles.appDescValue}>{appData?.car_brand}</span>
          </Box>
          <Box className={styles.appDesc}>
            <span className={styles.appDescTitle}>Модель</span>
            <span className={styles.appDescValue}>{appData?.vehicle}</span>
          </Box>

          {dynamicField?.map((field: any) => (
            <Box key={field?.fieldName} className={styles.appDesc}>
              <span className={styles.appDescTitle}>{field?.fieldName}</span>
              <span className={styles.appDescValue}>{field?.fieldValue}</span>
            </Box>
          ))}

          <Box className={styles.appDesc}>
            <span className={styles.appDescTitle}>Бюджет на покупку</span>
            <span className={styles.appDescValue}>{new Intl.NumberFormat('ru-RU').format((appData?.budget) as number)} ₽</span>
          </Box>



          {appData?.inn && (
            <Box className={styles.appDesc}>
              <span className={styles.appDescTitle}>ИНН</span>
              <span className={styles.appDescValue}>{appData?.inn}</span>
            </Box>
          )}

          {appData?.phone && (
            <Box className={styles.appDesc}>
              <span className={styles.appDescTitle}>Телефон</span>
              <span className={styles.appDescValue}>{appData?.phone}</span>
            </Box>
          )}

          {appData?.email && (
            <Box className={styles.appDesc}>
              <span className={styles.appDescTitle}>E-mail</span>
              <span className={styles.appDescValue}>{appData?.email}</span>
            </Box>
          )}

          {appData?.bio && (
            <Box className={styles.appDesc}>
              <span className={styles.appDescTitle}>ФИО</span>
              <span className={styles.appDescValue}>{appData?.bio}</span>
            </Box>
          )}

          {appData?.kp && (
            <Box className={styles.appDesc}>
              <span className={styles.appDescTitle}>Имеющееся предложение</span>
              <DownloadButton fileUrl={appData?.kp?.url} title={appData?.kp?.name} />
            </Box>
          )}

          {appData?.agent_inn && (
            <Box className={styles.appDesc}>
              <span className={styles.appDescTitle}>ИНН агента</span>
              <span className={styles.appDescValue}>{appData?.agent_inn}</span>
            </Box>
          )}
          {appData?.agent_phone && (
            <Box className={styles.appDesc}>
              <span className={styles.appDescTitle}>Телефон агента</span>
              <span className={styles.appDescValue}>{appData?.agent_phone}</span>
            </Box>
          )}
          {appData?.agent_email && (
            <Box className={styles.appDesc}>
              <span className={styles.appDescTitle}>E-mail агента</span>
              <span className={styles.appDescValue}>{appData?.agent_email}</span>
            </Box>
          )}
          {appData?.agent_bio && (
            <Box className={styles.appDesc}>
              <span className={styles.appDescTitle}>ФИО агента</span>
              <span className={styles.appDescValue}>{appData?.agent_bio}</span>
            </Box>
          )}
        </Box>
      )
    }
    if (type == 'leasing') {
      return (
        <Box className={styles.appWrapper}>
          <Box className={styles.appDesc}>
            <span className={styles.appDescTitle}>Траснспортное средство</span>
            <span className={styles.appDescValue}>{appData?.car_subtype}</span>
          </Box>
          <Box className={styles.appDesc}>
            <span className={styles.appDescTitle}>Марка</span>
            <span className={styles.appDescValue}>{appData?.car_brand}</span>
          </Box>
          <Box className={styles.appDesc}>
            <span className={styles.appDescTitle}>Модель</span>
            <span className={styles.appDescValue}>{appData?.vehicle}</span>
          </Box>


          <Box className={styles.appDesc}>
            <span className={styles.appDescTitle}>Количество ТС</span>
            <span className={styles.appDescValue}>{appData?.quantity}</span>
          </Box>


          <Box className={styles.appDesc}>
            <span className={styles.appDescTitle}>Стоимость лизинга</span>
            <span className={styles.appDescValue}>{new Intl.NumberFormat('ru-RU').format((appData?.price) as number)} ₽</span>
          </Box>
          <Box className={styles.appDesc}>
            <span className={styles.appDescTitle}>Первоначальный взнос</span>
            <span className={styles.appDescValue}>{appData?.initial_payment} %</span>
          </Box>
          <Box className={styles.appDesc}>
            <span className={styles.appDescTitle}>Срок лизинга</span>
            <span className={styles.appDescValue}>{appData?.lease_period} мес.</span>
          </Box>


          {appData?.inn && (
            <Box className={styles.appDesc}>
              <span className={styles.appDescTitle}>ИНН</span>
              <span className={styles.appDescValue}>{appData?.inn}</span>
            </Box>
          )}

          {appData?.phone && (
            <Box className={styles.appDesc}>
              <span className={styles.appDescTitle}>Телефон</span>
              <span className={styles.appDescValue}>{appData?.phone}</span>
            </Box>
          )}

          {appData?.email && (
            <Box className={styles.appDesc}>
              <span className={styles.appDescTitle}>E-mail</span>
              <span className={styles.appDescValue}>{appData?.email}</span>
            </Box>
          )}

          {appData?.bio && (
            <Box className={styles.appDesc}>
              <span className={styles.appDescTitle}>ФИО</span>
              <span className={styles.appDescValue}>{appData?.bio}</span>
            </Box>
          )}


          {appData?.kp && (
            <Box className={styles.appDesc}>
              <span className={styles.appDescTitle}>Имеющееся предложение</span>
              <DownloadButton fileUrl={appData?.kp?.url} title={appData?.kp?.name} />
            </Box>
          )}

          {appData?.agent_inn && (
            <Box className={styles.appDesc}>
              <span className={styles.appDescTitle}>ИНН агента</span>
              <span className={styles.appDescValue}>{appData?.agent_inn}</span>
            </Box>
          )}
          {appData?.agent_phone && (
            <Box className={styles.appDesc}>
              <span className={styles.appDescTitle}>Телефон агента</span>
              <span className={styles.appDescValue}>{appData?.agent_phone}</span>
            </Box>
          )}
          {appData?.agent_email && (
            <Box className={styles.appDesc}>
              <span className={styles.appDescTitle}>E-mail агента</span>
              <span className={styles.appDescValue}>{appData?.agent_email}</span>
            </Box>
          )}
          {appData?.agent_bio && (
            <Box className={styles.appDesc}>
              <span className={styles.appDescTitle}>ФИО агента</span>
              <span className={styles.appDescValue}>{appData?.agent_bio}</span>
            </Box>
          )}
        </Box>
      )
    }
  }

  const renderOffer = () => {
    if (type == 'select') {
      return(
        <Box key={offerData.id} className={styles.offerBlock}>
          <Box className={styles.appDesc}>
            <span className={styles.appDescTitle}>Состояние</span>
            <span className={styles.appDescValue}>{offerData?.condition}</span>
          </Box>
          <Box className={styles.appDesc}>
            <span className={styles.appDescTitle}>Количество ТС</span>
            <span className={styles.appDescValue}>{offerData?.quantity}</span>
          </Box>
          <Box className={styles.appDesc}>
            <span className={styles.appDescTitle}>Траснспортное средство</span>
            <span className={styles.appDescValue}>{offerData?.car_subtype}</span>
          </Box>
          <Box className={styles.appDesc}>
            <span className={styles.appDescTitle}>Марка</span>
            <span className={styles.appDescValue}>{offerData?.car_brand}</span>
          </Box>
          <Box className={styles.appDesc}>
            <span className={styles.appDescTitle}>Модель</span>
            <span className={styles.appDescValue}>{offerData?.car_model}</span>
          </Box>

          <Box className={styles.appDesc}>
            <span className={styles.appDescTitle}>Стоимость</span>
            <span className={styles.appDescValue}>{new Intl.NumberFormat('ru-RU').format((offerData?.price) as number)} ₽</span>
          </Box>

          {/*{dynamicField.length > 0 && (*/}
          {/*  dynamicField.map((field:any) => (*/}
          {/*    <Box key={field.nameField} className={styles.appDesc}>*/}
          {/*      <span className={styles.appDescTitle}>{field.nameField}</span>*/}
          {/*      <span className={styles.appDescValue}>{field.valueField}</span>*/}
          {/*    </Box>*/}
          {/*  ))*/}
          {/*)}*/}



          <Box className={styles.appDesc}>
            <span className={styles.appDescTitle}>Наличие</span>
            <span
              className={clsx([`${styles.appDescValue}`, isStock(offerData?.availability) && `${styles.stock}`, isOrder(offerData?.availability) && `${styles.order}`])}>
              {offerData?.availability}
            </span>
          </Box>
          <Box className={styles.appDesc}>
            <span className={styles.appDescTitle}>Срок поставки</span>
            <span className={styles.appDescValue}>{offerData?.delivery_time}</span>
          </Box>
          <Box className={styles.appDesc}>
            <span className={styles.appDescTitle}>Город</span>
            <span className={styles.appDescValue}>{offerData?.city}</span>
          </Box>

          {offerData?.kp && (
            <Box className={styles.appDesc}>
              <span className={styles.appDescTitle}>Коммерческое предложение</span>
              <DownloadButton fileUrl={offerData?.kp?.url} title={offerData?.kp?.name} />
            </Box>
          )}

          {(acceptedDisplay && offerData.status_accept ) && (<Box className={styles.acceptBtn}>
            <ButtonCustom disabled={loading} onClick={handleAcceptOffer}>
              {loading ? 'Подождите...' : 'Подтвердить предложение'}
            </ButtonCustom>
          </Box>)}
        </Box>
      )
    }
    if (type == 'leasing') {
      return(
        <Box key={offerData.id} className={styles.offerBlock}>
          <Box className={styles.appDesc}>
            <span className={styles.appDescTitle}>Траснспортное средство</span>
            <span className={styles.appDescValue}>{offerData?.car_subtype}</span>
          </Box>
          <Box className={styles.appDesc}>
            <span className={styles.appDescTitle}>Марка</span>
            <span className={styles.appDescValue}>{offerData?.car_brand}</span>
          </Box>
          <Box className={styles.appDesc}>
            <span className={styles.appDescTitle}>Модель</span>
            <span className={styles.appDescValue}>{offerData?.vehicle}</span>
          </Box>
          <Box className={styles.appDesc}>
            <span className={styles.appDescTitle}>Количество ТС</span>
            <span className={styles.appDescValue}>{offerData?.quantity}</span>
          </Box>
          <Box className={styles.appDesc}>
            <span className={styles.appDescTitle}>Стоимость</span>
            <span className={styles.appDescValue}>{new Intl.NumberFormat('ru-RU').format((offerData?.price) as number)} ₽</span>
          </Box>

          <Box className={styles.appDesc}>
            <span className={styles.appDescTitle}>Первоначальный взнос</span>
            <span className={styles.appDescValue}>{offerData?.initial_payment} %</span>
          </Box>

          <Box className={styles.appDesc}>
            <span className={styles.appDescTitle}>Срок лизинга</span>
            <span className={styles.appDescValue}>{offerData?.lease_period} мес.</span>
          </Box>

          <Box className={styles.appDesc}>
            <span className={styles.appDescTitle}>Общая сумма лизинговых платежей</span>
            <span className={styles.appDescValue}>{new Intl.NumberFormat('ru-RU').format((offerData?.sum_total) as number)} ₽</span>
          </Box>

          <Box className={styles.appDesc}>
            <span className={styles.appDescTitle}>Размер ежемесячного платежа</span>
            <span className={styles.appDescValue}>{new Intl.NumberFormat('ru-RU').format((offerData?.month_pay) as number)} ₽</span>
          </Box>

          <Box className={styles.appDesc}>
            <span className={styles.appDescTitle}>Разница</span>
            <span className={styles.appDescValue}>{new Intl.NumberFormat('ru-RU').format((offerData?.sum_diff) as number)} ₽</span>
          </Box>
          <Box className={styles.appDesc}>
            <span className={styles.appDescTitle}>Удорожание</span>
            <span className={styles.appDescValue}>{offerData?.rise_price} %</span>
          </Box>
          <Box className={styles.appDesc}>
            <span className={styles.appDescTitle}>Город</span>
            <span className={styles.appDescValue}>{offerData?.city}</span>
          </Box>

          {offerData?.calc && (
            <Box className={styles.appDesc}>
              <span className={styles.appDescTitle}>Расчет по форме</span>
              <DownloadButton fileUrl={offerData?.calc?.url} title={offerData?.calc?.name} />
            </Box>
          )}

          {offerData?.kp && (
            <Box className={styles.appDesc}>
              <span className={styles.appDescTitle}>Коммерческое предложение</span>
              <DownloadButton fileUrl={offerData?.kp?.url} title={offerData?.kp?.name} />
            </Box>
          )}

          {acceptedDisplay && (<Box className={styles.acceptBtn}>
            <ButtonCustom disabled={loading} onClick={handleAcceptOffer}>
              {loading ? 'Подождите...' : 'Подтвердить предложение'}
            </ButtonCustom>
          </Box>)}
        </Box>
      )
    }
  }


  return (
    <Box className={styles.offerWrapper}>
      <Box className={styles.offerInside}>
        <DashboardHeaderBackLink link={-2} title={`Заявка № ${appData.id}`} />
        {renderApp()}
      </Box>

      <Box className={styles.offerInside}>
        <DashboardHeaderBackLink link={-1} title={'Вернуться к предложениям'} />
        {renderOffer()}
      </Box>
    </Box>
  );
};
